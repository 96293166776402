import {
  Box,
  Button,
  DrawerContent,
  RichTextEditor,
} from "@adminjs/design-system";
import { ActionHeader, ActionProps, ApiClient } from "adminjs";
import React, { useEffect, useState } from "react";
import { render } from "storyblok-rich-text-react-renderer";
import { Comment } from "../types";

const api = new ApiClient();

const OpportunityCommentsHistory = (props: ActionProps) => {
  const { record, action } = props;
  const [comments, setComments] = useState<Comment[]>([]);
  const [newComment, setNewComment] = useState<string | null>(null);
  const saveNewComment = async () => {
    if (!newComment) return;
    const response = await api.resourceAction({
      resourceId: "LeadComments",
      actionName: "new",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: {
        lead: record?.id,
        comment: newComment,
      },
    });
    if (response.status === 200) {
      const { data } = response;
      const { record } = data;
      const { params } = record;
      const c = {
        id: params.id,
        comment: params.comment,
        createdAt: params.createdAt,
        commentedBy:
          typeof record.populated.user === "string"
            ? record.populated.user
            : record.populated.user.params.name,
      };
      setComments([c, ...comments]);
    }
  };
  useEffect(() => {
    api
      .resourceAction({
        resourceId: "LeadComments",
        actionName: "list",
        params: {
          "filters.lead": record?.id,
          direction: "desc",
          sortBy: "createdAt",
        },
      })
      .then((response) => {
        if (response) {
          const { data } = response;
          const { records } = data;
          setComments(
            records.map((comment: { params: any; populated: any }) => ({
              ...comment.params,
              commentedBy:
                typeof comment.populated.user === "string"
                  ? comment.populated.user
                  : comment.populated.user.params.name,
            })),
          );
        }
      });
  }, [record]);
  return (
    <Box flex flex-direction="column">
      <DrawerContent>
        {action?.showInDrawer ? <ActionHeader {...props} /> : null}
        <RichTextEditor
          value={newComment}
          onChange={(value) => setNewComment(value)}
        />
        <Box flex justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            size="lg"
            mt="xl"
            onClick={saveNewComment}
          >
            Save
          </Button>
        </Box>
        {comments.map((c) => (
          <Box
            variant="grey"
            mt="xl"
            flex
            flexDirection="column"
            justifyContent="start"
          >
            <div dangerouslySetInnerHTML={{ __html: render(c.comment) }}></div>
            <hr style={{ border: "1px solid #ccc", margin: "10px 0px" }} />
            <Box flex flexDirection="row" justifyContent="space-between">
              <div style={{ marginTop: "2px" }}>
                {new Date(c.createdAt).toLocaleString()}
              </div>
              <div style={{ marginTop: "2px" }}>{c.commentedBy}</div>
            </Box>
          </Box>
        ))}
      </DrawerContent>
    </Box>
  );
};

export default OpportunityCommentsHistory;
