import { Box, FormGroup, FormMessage, Select } from "@adminjs/design-system";
import { EditPropertyProps, ErrorBoundary, PropertyLabel } from "adminjs";
import React, { useEffect, useState } from "react";

import type { SelectOption } from "../types";

const SalesAgentComponent: React.FC<EditPropertyProps> = ({
  where,
  property,
  record,
  onChange,
}) => {
  const { availableValues, props: additionalProps } = property;
  const [statuses, setStatuses] = useState<SelectOption[]>();
  const [status, setStatus] = useState<SelectOption>();
  const [visible, setVisible] = useState(false);
  const testId = `property-${where}-${property.path}`;

  const handleSelectionChange = (selectedStatus: SelectOption) => {
    setStatus(selectedStatus);
    onChange({
      ...record,
      params: {
        ...record.params,
        salesAgent: selectedStatus.value,
      },
    });
  };

  useEffect(() => {
    if (availableValues) {
      setStatuses(availableValues as SelectOption[]);
      const selectedSalesAgent = availableValues.find(
        (av) => (av.value as string) === record.params.salesAgent,
      );
      setStatus(selectedSalesAgent as SelectOption);
    }
    const convertedLeadStatusId = additionalProps.leadStatuses.find(
      (ls: { name: string }) => ls.name === "CONVERTED",
    ).id;
    if (record.params.leadStatus === convertedLeadStatusId) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [setStatuses, setStatus, record]);

  return (
    <>
      {visible ? (
        <ErrorBoundary>
          <Box data-testid={testId}>
            <FormGroup>
              <PropertyLabel property={property} />
              <Select
                options={statuses}
                value={status}
                onChange={handleSelectionChange}
              ></Select>
              <FormMessage></FormMessage>
            </FormGroup>
          </Box>
        </ErrorBoundary>
      ) : null}
    </>
  );
};

export default SalesAgentComponent;
