import { Box, FormGroup, FormMessage, Select } from "@adminjs/design-system";
import {
  ApiClient,
  EditPropertyProps,
  ErrorBoundary,
  PropertyLabel,
} from "adminjs";
import React, { useEffect, useState } from "react";

import type { SelectOption } from "../types";

const LeadStatusComponent: React.FC<EditPropertyProps> = ({
  where,
  property,
  record,
  onChange,
}) => {
  const [statues, setStatues] = useState<SelectOption[]>();
  const [status, setStatus] = useState<SelectOption>();
  const testId = `property-${where}-${property.path}`;
  const api = new ApiClient();

  const handleSelectionChange = (selectedStatus: SelectOption) => {
    setStatus(selectedStatus);
    onChange({
      ...record,
      params: {
        ...record.params,
        leadStatus: selectedStatus.value,
      },
    });
  };

  useEffect(() => {
    api
      .resourceAction({
        resourceId: "LeadStatus",
        actionName: "search",
      })
      .then((response) => {
        const options = response.data.records.map(
          (r: { params: { id: any; name: any } }) => ({
            value: r.params.id,
            label: r.params.name,
          }),
        );
        setStatues(options);
        setStatus(
          options.find(
            (o: SelectOption) => o.value === record.params.leadStatus,
          ),
        );
      })
      .catch(console.error);
  }, []);

  return (
    <ErrorBoundary>
      <Box data-testid={testId}>
        <FormGroup>
          <PropertyLabel property={property} />
          <Select
            options={statues}
            value={status}
            onChange={handleSelectionChange}
          ></Select>
          <FormMessage></FormMessage>
        </FormGroup>
      </Box>
    </ErrorBoundary>
  );
};

export default LeadStatusComponent;
